import "core-js/modules/es.array.push.js";
import { getActivePage, AppendExamPaiwei } from "@/api/api";
export default {
  name: "activeList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      appendInfo: {
        active_id: '',
        user_no: '',
        exam_no: ''
      },
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getActivePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(id) {
      let data = id || '';
      this.$root.useRouter.push({
        path: "/examSetting/examSortDo",
        query: {
          id: data
        }
      });
    },
    showInfo(id) {
      let data = id || '';
      this.$root.useRouter.push({
        path: "/examSetting/examSortUser",
        query: {
          id: data
        }
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleAppend(id) {
      this.dialogFormVisible = true;
      this.appendInfo = {
        active_id: '',
        user_no: '',
        exam_no: ''
      };
      this.appendInfo.active_id = id;
    },
    onSubmit() {
      if (!this.appendInfo.exam_no) {
        this.$root.ElMessage.error('请填写考号');
        return false;
      }
      if (!this.appendInfo.user_no) {
        this.$root.ElMessage.error('请填写学号');
        return false;
      }
      AppendExamPaiwei(this.appendInfo).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: '追加成功'
          });
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};